import React, { useState, useEffect } from "react";
import { Button, InputNumber, Switch } from "antd";
import ModalCommon from "../../../components/Modal";

import { TEXT_DEF } from "../../../constant/text";
import { RIGHTS } from "../../../constant/rights";
import { hasRight } from "../../../components/until/right";
import API, {ADMIN_COINFLIP} from "../../../network/api";
import CustomInputNumber from "../../../components/common/CustomInputNumber";

const CoinflipSetting = () => {
  const [isMaintenance, setIsMaintenance] = useState();
  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [basicPayoutMulti, setBasicPayoutMulti] = useState(0);
  const [data, setData] = useState([]);
  const [valueToUpdate, setValueToUpdate] = useState({});
  const [loading, setLoading] = useState(false);
	const labels = {
    firstLose: 'Thắng lần đầu (đơn vị 1 = 1000 VND)',
    bonusRate: 'Tỷ lệ bonus',
    revRate: 'Tỷ lệ rev',
    ratioWin: 'Tỷ lệ thắng',
    stepRatio: 'Bước tỷ lệ',
    basicPayoutMulti: 'Tỷ lệ trả thưởng bắt đầu',
  };

  useEffect(() => {
    const getDataDiamond = async () => {
      const response = await API.get(`${ADMIN_COINFLIP}/getConfig`);
      const resultGame = response.data?.data?.result;
      const filteredData = resultGame.filter((item) => item.key === 'firstLose' || item.key === 'bonusRate' || item.key === 'revRate' || item.key === 'ratioWin');

      setData(filteredData);
    };

    getDataDiamond();
  }, []);

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const showConfirm = (key) => {
    let params;
    const id = data?.find((x) => x.key == key)?.id;
    switch (key) {
      case "isMaintenance":
        params = {
          key: "isMaintenance",
          value: isMaintenance.toString(),
        };
        break;

      case "revRate":
        params = {
          key: "revRate",
          value: valueToUpdate[key].toString(),
        };
        break;

      case "firstLose":
        params = {
          key: 'firstLose',
          value: valueToUpdate[key].toString(),
        };
        break;

      case "bonusRate":
        params = {
          key: 'bonusRate',
          value: valueToUpdate[key].toString(),
        };
        break;

      case "ratioWin":
        params = {
          key: 'ratioWin',
          value: valueToUpdate[key].toString(),
        };
        break;

      case "stepRatio":
        params = {
          key: 'stepRatio',
          value: valueToUpdate[key].toString(),
        };
        break;

      case "basicPayoutMulti":
        params = {
          key: 'basicPayoutMulti',
          value: valueToUpdate[key].toString(),
        };
        break;
      default:
        break;
    }
    const dataReq = {
      id: id,
      params: params,
    };
    setDataUp(dataReq);
    setIsShowModalConfirm(true);
  };

  const onRequest = async () => {
    if (!dataUp) return;
    await API.patch(
      `${ADMIN_COINFLIP}/updateConfig/${dataUp?.id}`,
      dataUp?.params
    )
      .then((response) => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          setLoading(true);
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetKey = (value) => {
    showConfirm(value);
  };

  const handleInputChange = (key, newValue) => {
    setValueToUpdate((prev) => ({ ...prev, [key]: Number(newValue) }));
  };

  const renderInputFields = () => {
    if (!data || data.length === 0) return;

    return (
      <>
        {data.length > 0 &&
          data?.map((item, index) => (
            <>
              <div className="col-12 flex" key={index}>
                <div className="col-3 text-left">{labels[item?.key]}</div>
                <div className="col-6">
                  <CustomInputNumber min={0} max={9999} step={0.01} value={item?.value} onChange={(e) => handleInputChange(item.key, e)} />
                </div>
                {hasRight([RIGHTS.EditSettingOriginals]) ? (
                  <div className="col-3 text-left">
                    <Button
                      type="primary"
                      onClick={() => {
                        handleGetKey(item?.key);
                      }}
                    >
                      {TEXT_DEF.text0016}
                    </Button>
                  </div>
                ) : null}
              </div>
              <div style={{ margin: '10px 0px' }} />
            </>
          ))}
      </>
    );
  };

  return (
    <>
      {data ? (
        <div
          className="col-12 flex-col"
          style={{ display: "flex", marginTop: 30, alignItems: "center" }}
        >
          <div className="col-12 flex">
            <div className="col-3 text-left">{TEXT_DEF.text0065}</div>
            <div className="col-6">
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                defaultChecked={
                  data?.find((x) => x.key == "isMaintenance")?.value === "true"
                    ? true
                    : false
                }
                style={{ width: 60, marginTop: "5px" }}
                onChange={(e) => handleChangeSwitch(e)}
              />
            </div>
            {hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button
                  type="primary"
                  onClick={() => showConfirm("isMaintenance")}
                >
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null}
          </div>
          <div style={{ margin: "10px 0px" }} />
          {renderInputFields()}
          {isShowModalConfirm && (
            <ModalCommon
              isShow={isShowModalConfirm}
              message={TEXT_DEF.text0071}
              txtBtnAcc={TEXT_DEF.text0008}
              textBtnClose={TEXT_DEF.text0072}
              actionNext={onRequest}
              close={() => {
                setIsShowModalConfirm(false);
                setDataUp(undefined);
              }}
            />
          )}
          {isShowModalSuccess && (
            <ModalCommon
              isShow={isShowModalSuccess}
              message={TEXT_DEF.text0073}
              txtBtnAcc={TEXT_DEF.text0007}
              actionNext={() => {
                setIsShowModalSuccess(false);
              }}
              close={() => {
                setIsShowModalSuccess(false);
              }}
            />
          )}
        </div>
      ) : null}
    </>
  );
};

const renderIp = (
  label,
  defaultValue,
  onChangeIp,
  onUpdate,
  isHilo,
  onChangeIp2
) => {
  return (
    <div className="col-12 flex">
      <div className="col-3 text-left">{label}</div>
      <div className="col-6">
        <InputNumber
          style={{ width: "90%" }}
          min={0}
          onChange={onChangeIp}
          defaultValue={Number(defaultValue)}
        />
      </div>
      {hasRight([RIGHTS.EditSettingOriginals]) ? (
        <div className="col-3 text-left">
          <Button type="primary" onClick={onUpdate}>
            {TEXT_DEF.text0016}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default CoinflipSetting;
