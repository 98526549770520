import React, { useState, useEffect } from "react";
import { Button, InputNumber, Switch } from "antd";
import ModalCommon from "../../../components/Modal";

import { TEXT_DEF } from "../../../constant/text";
import { RIGHTS } from "../../../constant/rights";
import { hasRight } from "../../../components/until/right";
import API, {ADMIN_TOWER} from "../../../network/api";
import CustomInputNumber from "../../../components/common/CustomInputNumber";

const TowerSetting = () => {
  const [isMaintenance, setIsMaintenance] = useState();
  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [ratesWin, setRatesWin] = useState(0);
  const [firstLose, setRirstLose] = useState(0);
  const [ratioRev, setRatioRev] = useState(0);
  const [ratioBonus, setRatioBonus] = useState(0);
  const [ratesWinEasy, setRatesWinEasy] = useState(0);
  const [ratesWinBasic, setRatesWinBasic] = useState(0);
  const [ratesWinMedium, setRatesWinMedium] = useState(0);
  const [ratesWinHard, setRatesWinHard] = useState(0);
  const [ratesWinExtreme, setRatesWinExtreme] = useState(0);
  const [data, setData] = useState([]);
  const [valueToUpdate, setValueToUpdate] = useState({});
  const [loading, setLoading] = useState(false);
  const labels = {
    ratesWin: 'Tỷ lệ thắng',
    firstLose: 'Thắng lần đầu (đơn vị 1 = 1000 VND)',
    ratioRev: 'Tỷ lệ rev',
    ratioBonus: 'Tỷ lệ bonus',
    ratesWin_easy: 'Tỷ lệ dễ',
    ratesWin_basic: 'Tỷ lệ thắng cơ bản',
    ratesWin_medium: 'Tỷ lệ thắng trung bình',
    ratesWin_hard: 'Tỷ lệ thắng khó',
    ratesWin_extreme: 'Tỷ lệ thắng rất khó',
  };

  useEffect(() => {
    const getDataTower = async () => {
      const response = await API.get(`${ADMIN_TOWER}/getConfig`);
      const resultGame = response.data?.data?.result;
      setData(resultGame);
    };

    getDataTower();
  }, []);

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const showConfirm = (key) => {
    let params;
    const id = data?.find((x) => x.key === key)?.id;
    switch (key) {
      case "isMaintenance":
        params = {
          key: "isMaintenance",
          value: isMaintenance.toString(),
        };
        break;
      case "ratesWin":
        params = {
          key: "ratesWin",
          value: valueToUpdate[key].toString(),
        };
        break;
      case "firstLose":
        params = {
          key: 'firstLose',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "ratioRev":
        params = {
          key: 'ratioRev',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "ratioBonus":
        params = {
          key: 'ratioBonus',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "ratesWin_easy":
        params = {
          key: 'ratesWin_easy',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "ratesWin_basic":
        params = {
          key: 'ratesWin_basic',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "ratesWin_medium":
        params = {
          key: 'ratesWin_medium',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "ratesWin_hard":
        params = {
          key: 'ratesWin_hard',
          value: valueToUpdate[key].toString(),
        };
        break;
      case "ratesWin_extreme":
        params = {
          key: 'ratesWin_extreme',
          value: valueToUpdate[key].toString(),
        };
        break;
      default:
        break;
    }
    const dataReq = {
      id: id,
      params: params,
    };
    setDataUp(dataReq);
    setIsShowModalConfirm(true);
  };

  const onRequest = async () => {
    if (!dataUp) return;
    await API.patch(`${ADMIN_TOWER}/updateConfig/${dataUp?.id}`, dataUp?.params)
      .then((response) => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          setLoading(true);
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetKey = (value) => {
    showConfirm(value);
  };

  const handleInputChange = (key, newValue) => {
    setValueToUpdate((prev) => ({ ...prev, [key]: Number(newValue) }));
  };

  const renderInputFields = () => {
    if (!data || data.length === 0) return;

    const keysToFilter = ['ratesWin', 'firstLose', 'ratioRev', 'ratioBonus', 'ratesWin_easy', 'ratesWin_basic', 'ratesWin_medium', 'ratesWin_hard', 'ratesWin_extreme'];
    const filteredData = data.filter((item) => keysToFilter.includes(item.key));

    return (
      <>
        {filteredData.length > 0 &&
          filteredData?.map((item, index) => (
            <>
              <div className="col-12 flex" key={index}>
                <div className="col-3 text-left">{labels[item?.key]}</div>
                <div className="col-6">
                  <CustomInputNumber min={0} max={9999} step={0.01} value={item?.value} onChange={(e) => handleInputChange(item.key, e)} />
                </div>
                {hasRight([RIGHTS.EditSettingOriginals]) ? (
                  <div className="col-3 text-left">
                    <Button
                      type="primary"
                      onClick={() => {
                        handleGetKey(item?.key);
                      }}
                    >
                      {TEXT_DEF.text0016}
                    </Button>
                  </div>
                ) : null}
              </div>
              <div style={{ margin: '10px 0px' }} />
            </>
          ))}
      </>
    );
  };

  return (
    <>
      {data ? (
        <div
          className="col-12 flex-col"
          style={{ display: "flex", marginTop: 30, alignItems: "center" }}
        >
          <div className="col-12 flex">
            <div className="col-3 text-left">{TEXT_DEF.text0065}</div>
            <div className="col-6">
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                defaultChecked={
                  data?.find((x) => x.key === "isMaintenance")?.value === "true"
                    ? true
                    : false
                }
                style={{ width: 60, marginTop: "5px" }}
                onChange={(e) => handleChangeSwitch(e)}
              />
            </div>
            {hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button
                  type="primary"
                  onClick={() => showConfirm("isMaintenance")}
                >
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null}
          </div>
          <div style={{ margin: "10px 0px" }} />
          {renderInputFields()}
          {isShowModalConfirm && (
            <ModalCommon
              isShow={isShowModalConfirm}
              message={TEXT_DEF.text0071}
              txtBtnAcc={TEXT_DEF.text0008}
              textBtnClose={TEXT_DEF.text0072}
              actionNext={onRequest}
              close={() => {
                setIsShowModalConfirm(false);
                setDataUp(undefined);
              }}
            />
          )}
          {isShowModalSuccess && (
            <ModalCommon
              isShow={isShowModalSuccess}
              message={TEXT_DEF.text0073}
              txtBtnAcc={TEXT_DEF.text0007}
              actionNext={() => {
                setIsShowModalSuccess(false);
              }}
              close={() => {
                setIsShowModalSuccess(false);
              }}
            />
          )}
        </div>
      ) : null}
    </>
  );
};

const renderIp = (
  label,
  defaultValue,
  onChangeIp,
  onUpdate,
  isHilo,
  onChangeIp2
) => {
  return (
    <div className="col-12 flex">
      <div className="col-3 text-left">{label}</div>
      <div className="col-6">
        <InputNumber
          style={{ width: "90%" }}
          min={0}
          onChange={onChangeIp}
          defaultValue={Number(defaultValue)}
        />
      </div>
      {hasRight([RIGHTS.EditSettingOriginals]) ? (
        <div className="col-3 text-left">
          <Button type="primary" onClick={onUpdate}>
            {TEXT_DEF.text0016}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default TowerSetting;
