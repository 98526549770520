import React, { useState, useEffect, useCallback } from "react";
import { TEXT_DEF } from "../../../constant/text";
import { Button, Input, Table, InputNumber, Switch } from "antd";
import ModalCommon from "../../../components/Modal";
import API, { ADMIN_POKER } from "../../../network/api";
import { RIGHTS } from "../../../constant/rights";
import {hasRight} from "../../../components/until/right";
import CustomInputNumber from "../../../components/common/CustomInputNumber";

const PokerSetting = () => {
  const [isMaintenance, setIsMaintenance] = useState()
  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valueToUpdate, setValueToUpdate] = useState({});
  const labels = {
    firstLose: 'Thắng lần đầu (đơn vị 1 = 1000 VND)',
    ratioRev: 'Tỷ lệ Rev',
    ratioBonus: 'Tỷ lệ bonus',
    reward1Doi: 'Hệ số thưởng 1 đôi',
    reward2Doi: 'Hệ số thưởng 2 đôi',
    rewardXamCo: 'Hệ số thưởng xám cô',
    rewardSanh: 'Hệ số thưởng sảnh',
    rewardThung: 'Hệ số thưởng thùng',
    rewardCuLu: 'Hệ số thưởng cù lũ',
    rewardTuQuy: 'Hệ số thưởng tứ quý',
    rewardThungPhaSanh: 'Hệ số thưởng thùng phá sảnh',
  };

  useEffect(() => {
    const getPokerData = async () => {
      const response = await API.get(`${ADMIN_POKER}/getConfig`);
      const resultGame = response.data?.data?.result;
      setData(resultGame);
    };

    getPokerData();
  }, []);

  const showConfirm = (key) => {
    let params;
    const id = data?.find(x => x.key === key)?.id;
    switch (key) {
      case "isMaintenance":
        params = {
          key: "isMaintenance",
          value: isMaintenance.toString()
        }
        break;
      case "firstLose":
        params = {
          key: "firstLose",
          value: valueToUpdate[key].toString()
        }
        break;
      case "ratioRev":
        params = {
          key: "ratioRev",
          value: valueToUpdate[key].toString()
        }
        break;
      case "ratioBonus":
        params = {
          key: "ratioBonus",
          value: valueToUpdate[key].toString()
        }
        break;
      case "reward1Doi":
        params = {
          key: "reward1Doi",
          value: valueToUpdate[key].toString()
        }
        break;
      case "reward2Doi":
        params = {
          key: "reward2Doi",
          value: valueToUpdate[key].toString()
        }
        break;
      case "rewardXamCo":
        params = {
          key: "rewardXamCo",
          value: valueToUpdate[key].toString()
        }
        break;
      case "rewardSanh":
        params = {
          key: "rewardSanh",
          value: valueToUpdate[key].toString()
        }
        break;
      case "rewardThung":
        params = {
          key: "rewardThung",
          value: valueToUpdate[key].toString()
        }
        break;
      case "rewardCuLu":
        params = {
          key: "rewardCuLu",
          value: valueToUpdate[key].toString()
        }
        break;
      case "rewardTuQuy":
        params = {
          key: "rewardTuQuy",
          value: valueToUpdate[key].toString()
        }
        break;
      case "rewardThungPhaSanh":
        params = {
          key: 'rewardThungPhaSanh',
          value: valueToUpdate[key].toString(),
        };
        break;
      default: break;
    }
    const dataReq = {
      id: id,
      params: params
		}
    setDataUp(dataReq);
    setIsShowModalConfirm(true)
  }

  const onRequest = async () => {
    // console.log(rateMinWin)
    if (!dataUp) return;
    await API.patch(`${ADMIN_POKER}/updateConfig/${dataUp?.id}`, dataUp?.params)
      .then(response => {
        const result = response.data?.data;
        if (result?.code === 20004) {
					setLoading(true);
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      }).catch((error) => {
        console.log(error);
      })
  }

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const handleInputChange = (key, newValue) => {
    setValueToUpdate((prev) => ({ ...prev, [key]: Number(newValue) }));
  };

  const handleGetKey = (value) => {
    showConfirm(value);
  };

  const renderInputFields = () => {
    if (!data || data.length === 0) return;

    const filteredData = data.filter(
      (item) =>
        item.key === 'firstLose' ||
        item.key === 'ratioRev' ||
        item.key === 'ratioBonus' ||
        item.key === 'reward1Doi' ||
        item.key === 'reward2Doi' ||
        item.key === 'rewardXamCo' ||
        item.key === 'rewardSanh' ||
        item.key === 'rewardThung' ||
        item.key === 'rewardCuLu' ||
        item.key === 'rewardTuQuy' ||
        item.key === 'rewardThungPhaSanh'
    );

    return (
      <>
        {filteredData.length > 0 &&
          filteredData?.map((item, index) => (
            <>
              <div className="col-12 flex" key={index}>
                <div className="col-3 text-left">{labels[item?.key]}</div>
                <div className="col-6">
                  <CustomInputNumber min={0} max={9999} step={0.01} value={item?.value} onChange={(e) => handleInputChange(item.key, e)} />
                </div>
                {hasRight([RIGHTS.EditSettingOriginals]) ? (
                  <div className="col-3 text-left">
                    <Button
                      type="primary"
                      onClick={() => {
                        handleGetKey(item?.key);
                      }}
                    >
                      {TEXT_DEF.text0016}
                    </Button>
                  </div>
                ) : null}
              </div>
              <div style={{ margin: '10px 0px' }} />
            </>
          ))}
      </>
    );
  };

  const renderIp = (label, defaultValue, onChangeIp, onUpdate) => {
    return (
      <div className="col-12 flex">
        <div className="col-3 text-left">{label}</div>
        <div className="col-6">
          <InputNumber
            style={{ width: '90%' }}
            // addonAfter="%" 
            // min={10} max={100} 
            min={0}
            onChange={onChangeIp}
            defaultValue={Number(defaultValue)}
          />
        </div>
        {
          hasRight([RIGHTS.EditSettingOriginals]) ? (
            <div className="col-3 text-left">
              <Button
                type="primary"
                onClick={onUpdate}
              >
                {TEXT_DEF.text0016}
              </Button>
            </div>
          ) : null
        }
      </div>
    )
  }

  return (
    <>
    {
      data ? 
      <div className="col-12 flex-col"
        style={{ display: "flex", marginTop: 30, alignItems: "center" }}
      >
        <div className="col-12 flex">
          <div className="col-3 text-left">{TEXT_DEF.text0065}</div>
          <div className="col-6">
            <Switch
              checkedChildren="Bật"
              unCheckedChildren="Tắt"
              defaultChecked={data?.find(x => x.key == "isMaintenance")?.value === "true" ? true : false}
              style={{ width: 60, marginTop: "5px" }}
              onChange={(e) => handleChangeSwitch(e)}
            />
          </div>
          {
            hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button
                  type="primary"
                  onClick={() => showConfirm("isMaintenance")}
                >
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null
          }
        </div>
        <div style={{margin: "10px 0px"}} />
        {renderInputFields(data)}
        {/* {renderIp("Thắng lần đầu (đơn vị 1 = 1000 VND)", data?.find(x => x.key == "firstLose")?.value, (e) => setValueIp1(e), () => showConfirm("firstLose"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Tỉ lệ rev", data?.find(x => x.key == "ratioRev")?.value, (e) => setValueIp2(e), () => showConfirm("ratioRev"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Tỉ lệ bonus", data?.find(x => x.key == "ratioBonus")?.value, (e) => setValueIp3(e), () => showConfirm("ratioBonus"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng 1 đôi", data?.find(x => x.key == "reward1Doi")?.value, (e) => setValueIp4(e), () => showConfirm("reward1Doi"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng 2 đôi", data?.find(x => x.key == "reward2Doi")?.value, (e) => setValueIp5(e), () => showConfirm("reward2Doi"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng xám cô", data?.find(x => x.key == "rewardXamCo")?.value, (e) => setValueIp6(e), () => showConfirm("rewardXamCo"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng sảnh", data?.find(x => x.key == "rewardSanh")?.value, (e) => setValueIp7(e), () => showConfirm("rewardSanh"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng thùng", data?.find(x => x.key == "rewardThung")?.value, (e) => setValueIp8(e), () => showConfirm("rewardThung"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng cù lũ", data?.find(x => x.key == "rewardCuLu")?.value, (e) => setValueIp9(e), () => showConfirm("rewardCuLu"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng tứ quý", data?.find(x => x.key == "rewardTuQuy")?.value, (e) => setValueIp10(e), () => showConfirm("rewardTuQuy"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng thùng phá sảnh", data?.find(x => x.key == "rewardThungPhaSanh")?.value, (e) => setValueIp11(e), () => showConfirm("rewardThungPhaSanh"))} */}
      </div> : null}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0071}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0072}
          actionNext={onRequest}
          close={() => { setIsShowModalConfirm(false); setDataUp(undefined) }}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0073}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </>
  )
}

export default PokerSetting;