/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import { Button, InputNumber, Switch } from 'antd';
import ModalCommon from '../../../components/Modal';

import { TEXT_DEF } from '../../../constant/text';
import { RIGHTS } from '../../../constant/rights';
import { hasRight } from '../../../components/until/right';
import API, { ADMIN_LIMBO } from '../../../network/api';
import CustomInputNumber from '../../../components/common/CustomInputNumber';

const LimboSetting = () => {
  const [isMaintenance, setIsMaintenance] = useState();
  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [fromRange1, setFromRange1] = useState(0);
  const [fromRange2, setFromRange2] = useState(0);
  const [fromRange3, setFromRange3] = useState(0);
  const [fromRange4, setFromRange4] = useState(0);
  const [fromRange5, setFromRange5] = useState(0);
  const [toRange1, setToRange1] = useState(0);
  const [toRange2, setToRange2] = useState(0);
  const [toRange3, setToRange3] = useState(0);
  const [toRange4, setToRange4] = useState(0);
  const [toRange5, setToRange5] = useState(0);
  const [probability1, setProbability1] = useState(0);
  const [probability2, setProbability2] = useState(0);
  const [probability3, setProbability3] = useState(0);
  const [probability4, setProbability4] = useState(0);
  const [probability5, setProbability5] = useState(0);
  const [data, setData] = useState([]);
  const [valueToUpdate, setValueToUpdate] = useState({});
  const [loading, setLoading] = useState(false);
  const labels = {
    firstLose: 'Thắng lần đầu (đơn vị 1 = 1000 VND)',
    ratioRev: 'Tỷ lệ Rev',
    ratioBonus: 'Tỷ lệ bonus',
    ratio_win_max: 'Tỷ lệ thắng Max',
    ratio_win_min: 'Tỷ lệ thắng Min',
  };

  useEffect(() => {
    const getLimboData = async () => {
      const response = await API.get(`${ADMIN_LIMBO}/getConfig`);
      const resultGame = response.data?.data?.result;
      const filteredData = resultGame.filter(
        (item) => item.key === 'firstLose' || item.key === 'ratioRev' || item.key === 'ratioBonus' || item.key === 'ratio_win_max' || item.key === 'ratio_win_min' || item.key === 'isMaintenance'
      );

      setData(filteredData);
    };

    getLimboData();
  }, []);

  useEffect(() => {
    if (!data) return;

    const valueRanges = data?.find((x) => x.key === 'ranges')?.value || '';
    const valueProbabilities = data?.find((x) => x.key === 'probabilities')?.value || '';
    const ranges = valueRanges?.split(',');
    const probabilities = valueProbabilities?.split(',');
    setFromRange1(ranges?.[0]?.split('-')[0]);
    setToRange1(ranges?.[0]?.split('-')[1]);
    setFromRange2(ranges?.[1]?.split('-')[0]);
    setToRange2(ranges?.[1]?.split('-')[1]);
    setFromRange3(ranges?.[2]?.split('-')[0]);
    setToRange3(ranges?.[2]?.split('-')[1]);
    setFromRange4(ranges?.[3]?.split('-')[0]);
    setToRange4(ranges?.[3]?.split('-')[1]);
    setFromRange5(ranges?.[4]?.split('-')[0]);
    setToRange5(ranges?.[4]?.split('-')[1]);
    setProbability1(probabilities?.[0] || 0);
    setProbability2(probabilities?.[1] || 0);
    setProbability3(probabilities?.[2] || 0);
    setProbability4(probabilities?.[3] || 0);
    setProbability5(probabilities?.[4] || 0);
  }, [data]);

  const showConfirm = (key) => {
    let params;
    const id = data?.find((x) => x.key === key)?.id;

    switch (key) {
    case 'isMaintenance':
        params = {
          key: 'isMaintenance',
          value: isMaintenance.toString(),
        };
        break;
      case 'ratesWin':
        params = {
          key: 'ratesWin',
          value: valueToUpdate[key].toString(),
        };
        break;
      case 'firstLose':
        params = {
          key: 'firstLose',
          value: valueToUpdate[key].toString(),
        };
        break;
      case 'ratioRev':
        params = {
          key: 'ratioRev',
          value: valueToUpdate[key].toString(),
        };
        break;
      case 'ratioBonus':
        params = {
          key: 'ratioBonus',
          value: valueToUpdate[key].toString(),
        };
        break;
      case 'ratio_win_max':
        params = {
          key: 'ratio_win_max',
          value: valueToUpdate[key].toString(),
        };
        break;
      case 'ratio_win_min':
        params = {
          key: 'ratio_win_min',
          value: valueToUpdate[key].toString(),
        };
        break;
      // case 'probabilities':
      //   params = {
      //     key: 'probabilities',
      //     value: `${probability1.toString()},${probability2.toString()},${probability3.toString()},${probability4.toString()},${probability5.toString()}`,
      //   };
      //   break;
      // case 'ranges':
      //   params = {
      //     key: 'ranges',
      //     value: `${fromRange1.toString()}-${toRange1.toString()},${fromRange2.toString()}-${toRange2.toString()},${fromRange3.toString()}-${toRange3.toString()},${fromRange4.toString()}-${toRange4.toString()},${fromRange5.toString()}-${toRange5.toString()}`,
      //   };
      //   break;
    }
    const dataReq = {
      id: id,
      params: params,
    };
    setDataUp(dataReq);
    setIsShowModalConfirm(true);
  };

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const onRequest = async () => {
    if (!dataUp) return;
    await API.patch(`${ADMIN_LIMBO}/updateConfig/${dataUp?.id}`, dataUp?.params)
      .then((response) => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          setLoading(true);
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetKey = (value) => {
    showConfirm(value);
  };

  const handleInputChange = (key, newValue) => {
    setValueToUpdate((prev) => ({ ...prev, [key]: Number(newValue) }));
  };

  const renderInputFields = () => {
    if (!data || data.length === 0) return;

    const filteredData = data.filter(
      (item) => item.key === 'firstLose' || item.key === 'ratioRev' || item.key === 'ratioBonus' || item.key === 'ratio_win_max' || item.key === 'ratio_win_min');

    return (
      <>
        {filteredData.length > 0 &&
          filteredData?.map((item, index) => (
            <>
              <div className="col-12 flex" key={index}>
                <div className="col-3 text-left">{labels[item?.key]}</div>
                <div className="col-6">
                  <CustomInputNumber min={0} max={9999} step={0.01} value={item?.value} onChange={(e) => handleInputChange(item.key, e)} />
                </div>
                {hasRight([RIGHTS.EditSettingOriginals]) ? (
                  <div className="col-3 text-left">
                    <Button
                      type="primary"
                      onClick={() => {
                        handleGetKey(item?.key);
                      }}
                    >
                      {TEXT_DEF.text0016}
                    </Button>
                  </div>
                ) : null}
              </div>
              <div style={{ margin: '10px 0px' }} />
            </>
          ))}
      </>
    );
  };

  return (
    <>
      {data ? (
        <div className="col-12 flex-col" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
          <div className="col-12 flex">
            <div className="col-3 text-left">{TEXT_DEF.text0065}</div>
            <div className="col-6">
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                defaultChecked={data?.find((x) => x.key === 'isMaintenance')?.value === 'true' ? true : false}
                style={{ width: 60, marginTop: '5px' }}
                onChange={(e) => handleChangeSwitch(e)}
              />
            </div>
            {hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button type="primary" onClick={() => showConfirm('isMaintenance')}>
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null}
          </div>
          <div style={{ margin: '10px 0px' }} />
          {renderInputFields(data)}

          {isShowModalConfirm && (
            <ModalCommon
              isShow={isShowModalConfirm}
              message={TEXT_DEF.text0071}
              txtBtnAcc={TEXT_DEF.text0008}
              textBtnClose={TEXT_DEF.text0072}
              actionNext={onRequest}
              close={() => {
                setIsShowModalConfirm(false);
                setDataUp(undefined);
              }}
            />
          )}
          {isShowModalSuccess && (
            <ModalCommon
              isShow={isShowModalSuccess}
              message={TEXT_DEF.text0073}
              txtBtnAcc={TEXT_DEF.text0007}
              actionNext={() => {
                setIsShowModalSuccess(false);
              }}
              close={() => {
                setIsShowModalSuccess(false);
              }}
            />
          )}

          {/* <div className="col-12 flex">
            <div className="col-3 text-left">ranges</div>
            <div className="col-6">
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-2 text-left">Từ: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setFromRange1(value)}
                    value={Number(fromRange1)}
                  />
                </div>
                <div className="col-2 text-left ml-6">Đến: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setToRange1(value)}
                    value={Number(toRange1)}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-2 text-left">Từ: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setFromRange2(value)}
                    value={fromRange2}
                  />
                </div>
                <div className="col-2 text-left ml-6">Đến: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setToRange2(value)}
                    value={toRange2}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-2 text-left">Từ: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setFromRange3(value)}
                    value={fromRange3}
                  />
                </div>
                <div className="col-2 text-left ml-6">Đến: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setToRange3(value)}
                    value={toRange3}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-2 text-left">Từ: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setFromRange4(value)}
                    value={fromRange4}
                  />
                </div>
                <div className="col-2 text-left ml-6">Đến: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setToRange4(value)}
                    value={toRange4}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-2 text-left">Từ: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setFromRange5(value)}
                    value={fromRange5}
                  />
                </div>
                <div className="col-2 text-left ml-6">Đến: </div>
                <div className="col-3">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setToRange5(value)}
                    value={toRange5}
                  />
                </div>
              </div>
            </div>
            {hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button type="primary" onClick={() => showConfirm("ranges")}>
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null}
          </div>

          <div style={{ margin: "10px 0px" }} />
          <div className="col-12 flex">
            <div className="col-3 text-left">probabilities</div>
            <div className="col-6">
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-12">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setProbability1(value)}
                    value={probability1}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-12">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setProbability2(value)}
                    value={probability2}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-12">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setProbability3(value)}
                    value={probability3}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-12">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setProbability4(value)}
                    value={probability4}
                  />
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} />
              <div className="col-12 flex" style={{ alignItems: "center" }}>
                <div className="col-12">
                  <InputNumber
                    style={{ width: "90%" }}
                    min={0}
                    onChange={(value) => setProbability5(value)}
                    value={probability5}
                  />
                </div>
              </div>
            </div>
            {hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button
                  type="primary"
                  onClick={() => showConfirm("probabilities")}
                >
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null}
          </div> */}
        </div>
      ) : null}
    </>
  );
};

export default LimboSetting;
